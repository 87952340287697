var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, alignSelf, borderRadius, color, display, flex, flexWrap, fontSize, maxHeight, minHeight, minWidth, overflow, size, space, textAlign, themeGet, width, } from 'styled-system';
import CountDown from '../../common/components/CountDown';
import { Flex } from '../../common/components/Flex';
import { Grid, GridItem } from '../../common/components/Grid';
import Icon from '../../common/components/Icon';
import { Link } from '../../common/components/Link';
import { Heading, Text } from '../../common/components/Text';
import Block from '../../common/components/Voucher/Block';
import { PromoEvent } from '../../common/enums/Tracking';
import { useOpenDisclaimer } from '../../common/hooks/drawer';
import { usePromoEvents } from '../../common/hooks/dynamic-yield';
import { textWithEllipsis } from '../../common/theme/helper';
import { textDecoration, textOverflow, whiteSpace } from '../../common/theme/system-utilities';
import { getCampaignEventData } from '../../common/tracking/campaignEventData';
import { getPageAppTrackerProps } from '../../common/tracking/getTrackerProps';
import GlobalRibbonGridItemLeft from './GlobalRibbonGridItemLeft';
var ICON_SIZE = '23px';
var GlobalRibbonBlock = styled(Block)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), borderRadius, textDecoration);
GlobalRibbonBlock.defaultProps = {
    textDecoration: 'none',
    as: 'a',
    mb: 'sp_0',
    px: ['sp_0', null, null, 'sp_12'],
    py: 'sp_0',
};
var GlobalRibbonGrid = styled(Grid)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), flexWrap);
GlobalRibbonGrid.defaultProps = __assign(__assign({}, Grid.defaultProps), { bg: 'transparent', px: ['sp_8', 'sp_24', 'sp_48'], py: ['sp_8', 'sp_12', 'sp_20'], flexWrap: 'nowrap' });
var GlobalRibbonGridItemRight = styled(GridItem)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), textDecoration, display, alignItems, flexWrap, flex, minWidth);
GlobalRibbonGridItemRight.defaultProps = __assign(__assign({}, GridItem.defaultProps), { textDecoration: 'none', display: 'flex', alignItems: 'center', px: 'sp_0', flex: '1 1 0', flexWrap: ['wrap', 'nowrap'], minWidth: '0px' });
var DisclaimerLinkGrid = styled(Grid)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), flex);
DisclaimerLinkGrid.defaultProps = __assign(__assign({}, Grid.defaultProps), { bg: 'transparent', px: ['sp_8', 'sp_24', 'sp_48'], justifyContent: 'flex-end' });
var DisclaimerLink = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  cursor: pointer;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  cursor: pointer;\n"])), space, color, alignSelf, textDecoration);
DisclaimerLink.defaultProps = {
    as: 'span',
    m: 'sp_0',
    mt: 'sp_4',
    fontSize: 'fs_12',
    alignSelf: 'flex-end',
    textDecoration: 'underline',
};
var DisclaimerLinkRounded = styled(DisclaimerLink)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), textAlign, width);
DisclaimerLinkRounded.defaultProps = __assign(__assign({}, DisclaimerLink.defaultProps), { lineHeight: 'lh_16', pb: 'sp_4', textAlign: 'right', width: 1 });
var CenterContainer = styled(Flex)(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
CenterContainer.defaultProps = {
    flex: ['1 1 auto', '1 1 0'],
    flexWrap: 'wrap',
    alignItems: 'center',
    pr: ['sp_0', 'sp_24', 'sp_32'],
};
var HeadlineText = styled(Heading)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), maxHeight, textWithEllipsis());
HeadlineText.defaultProps = __assign(__assign({}, Heading.defaultProps), { as: 'p', my: 'sp_0', mr: [0, 'sp_16', 'sp_32', 'sp_48'], fontSize: ['fs_16', 'fs_20', null, 'fs_24'], lineHeight: ['lh_20', 'lh_24', null, 'lh_28'] });
var Subtitle = styled(Text)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), display, overflow, whiteSpace, textOverflow);
Subtitle.defaultProps = __assign(__assign({}, Text.defaultProps), { as: 'p', display: ['none', 'flex'], m: 'sp_0', overflow: 'hidden', fontSize: ['fs_16', null, null, 'fs_18'], whiteSpace: 'nowrap', textOverflow: 'ellipsis' });
var CountDownContainer = styled(CountDown)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  > p {\n    ", ";\n\n    margin-bottom: 0;\n  }\n\n  > div {\n    min-width: 0;\n    font-size: ", "px;\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  > p {\n    ", ";\n\n    margin-bottom: 0;\n  }\n\n  > div {\n    min-width: 0;\n    font-size: ", "px;\n  }\n"])), display, flexWrap, color, fontSize, themeGet('fontSizes.fs_16'));
CountDownContainer.defaultProps = {
    display: ['none', 'flex'],
    flexWrap: 'nowrap',
    fontSize: ['fs_16', null, null, 'fs_18'],
};
var CTAContainer = styled(Flex)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), minWidth);
CTAContainer.defaultProps = {
    minWidth: '0px', // didn't set it to 0 because then in Storybook it gets changed to 320px
};
var CTAText = styled(Text)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), overflow, whiteSpace, textOverflow);
CTAText.defaultProps = __assign(__assign({}, Text.defaultProps), { as: 'p', m: 'sp_0', fontSize: ['fs_16', null, null, 'fs_18'], overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' });
var ArrowRight = styled(Icon)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  fill: ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  fill: ", ";\n"])), size, minWidth, minHeight, function (_a) {
    var iconColor = _a.color;
    return iconColor;
});
ArrowRight.defaultProps = {
    size: ICON_SIZE,
    name: 'arrowRight',
};
var DisclaimerInfo = function (_a) {
    var legalDisclaimerLinkLabel = _a.legalDisclaimerLinkLabel, legalDisclaimerLabelColor = _a.legalDisclaimerLabelColor, legalDisclaimerContent = _a.legalDisclaimerContent, isRounded = _a.isRounded;
    var onDisclaimerClick = useOpenDisclaimer({ html: legalDisclaimerContent }, { preventDefault: true });
    var hasDisclaimer = Boolean(legalDisclaimerContent && legalDisclaimerLinkLabel);
    var DisclaimerLinkGridItem = isRounded ? DisclaimerLinkRounded : DisclaimerLink;
    return (hasDisclaimer && (React.createElement(DisclaimerLinkGrid, null,
        React.createElement(DisclaimerLinkGridItem, { color: legalDisclaimerLabelColor ? legalDisclaimerLabelColor : 'sys.neutral.text.strong', "data-testid": "disclaimer-link", onClick: onDisclaimerClick }, legalDisclaimerLinkLabel))));
};
/* eslint-disable complexity, max-lines, max-lines-per-function */
var GlobalRibbon = function (_a) {
    var stoererText = _a.stoererText, stoererTextColor = _a.stoererTextColor, stoererLineColor = _a.stoererLineColor, headlineText = _a.headlineText, headlineColor = _a.headlineColor, subtitleText = _a.subtitleText, subtitleColor = _a.subtitleColor, countdownLabel = _a.countdownLabel, countdownColor = _a.countdownColor, countdownEndDate = _a.countdownEndDate, ctaText = _a.ctaText, ctaLink = _a.ctaLink, ctaLinkTarget = _a.ctaLinkTarget, ctaColor = _a.ctaColor, globalRibbonStartBgColor = _a.globalRibbonStartBgColor, globalRibbonEndBgColor = _a.globalRibbonEndBgColor, dyVariationId = _a.dyVariationId, dyDecisionId = _a.dyDecisionId, placement = _a.placement, name = _a.name, className = _a.className, isRounded = _a.isRounded, props = __rest(_a, ["stoererText", "stoererTextColor", "stoererLineColor", "headlineText", "headlineColor", "subtitleText", "subtitleColor", "countdownLabel", "countdownColor", "countdownEndDate", "ctaText", "ctaLink", "ctaLinkTarget", "ctaColor", "globalRibbonStartBgColor", "globalRibbonEndBgColor", "dyVariationId", "dyDecisionId", "placement", "name", "className", "isRounded"]);
    var onPromoView = usePromoEvents({
        dyDecisionId: dyDecisionId,
        dyVariationId: dyVariationId,
        placement: placement,
        name: name,
    }).onPromoView;
    var hasDisclaimer = Boolean(props.legalDisclaimerLinkLabel && props.legalDisclaimerContent);
    var linkProps = getPageAppTrackerProps(__assign({ href: ctaLink }, (ctaLinkTarget && { target: ctaLinkTarget })), getCampaignEventData({
        promoEvent: PromoEvent.PromoClick,
        dyDecisionId: dyDecisionId,
        dyVariationId: dyVariationId,
        placement: placement,
        name: name,
    }));
    return (React.createElement(Flex, { flexDirection: "column", mb: "sp_8", className: className },
        React.createElement(GlobalRibbonBlock, __assign({}, linkProps, { "data-testid": "global-ribbon-container", startColor: globalRibbonStartBgColor, endColor: globalRibbonEndBgColor, onView: onPromoView }, (isRounded && { borderRadius: 'medium' })),
            React.createElement(GlobalRibbonGrid, __assign({}, (isRounded && hasDisclaimer && { paddingBottom: 'sp_0' })),
                React.createElement(GlobalRibbonGridItemLeft, { stoererTextColor: stoererTextColor, stoererLineColor: stoererLineColor, stoererText: stoererText }),
                React.createElement(GlobalRibbonGridItemRight, null,
                    React.createElement(CenterContainer, null,
                        React.createElement(HeadlineText, { "data-testid": "headline-text", color: headlineColor }, headlineText),
                        !!subtitleText && !countdownEndDate && (React.createElement(Subtitle, { "data-testid": "subtitle-text", color: subtitleColor }, subtitleText)),
                        !!countdownEndDate && (React.createElement(CountDownContainer, { color: countdownColor, borderColor: countdownColor, label: countdownLabel, endDate: countdownEndDate }))),
                    !!ctaText && (React.createElement(CTAContainer, null,
                        React.createElement(CTAText, { "data-testid": "cta-text", color: ctaColor }, ctaText),
                        React.createElement(ArrowRight, { color: ctaColor }))))),
            isRounded && React.createElement(DisclaimerInfo, __assign({}, props, { isRounded: true }))),
        !isRounded && React.createElement(DisclaimerInfo, __assign({}, props))));
};
export default GlobalRibbon;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
